import React from "react";
import Layout from "components/layout";
import { graphql } from "gatsby";
import SEO from "components/seo";
import { colors } from "style/theme";
import { jsx } from "@emotion/core";
import { mq } from "../utils/helper";

function Team({ data }) {
  const { title, intro, team_members: teamMembers } = data.pageContent.frontmatter;

  return (
    <div
      css={{
        backgroundColor: colors.dark,
        position: "relative",
        overflow: "hidden"
      }}
    >
      <img
        src="/assets/about-background.gif"
        css={{
          position: "absolute",
          top: 0,
          left: "30%",
          width: "60%",
          height: "auto",
          zIndex: 0,
          opacity: 0.2
        }}
      />
      <Layout css={{ zIndex: 2 }}>
        <div
          css={{
            maxWidth: "1200px",
            margin: "100px auto",
            position: "relative",
            zIndex: 2
          }}
        >
          <h2
            css={{
              color: colors.primary,
              margin: 0,
              fontSize: "48px",
              display: "block",
              [mq[1]]: {
                marginLeft: "2rem"
              }
            }}
          >
            {title}
          </h2>
          {!!intro && (
            <p
              css={{
                margin: "1rem 0",
                color: colors.light,
                fontSize: "21px",
              }}
            >
              {intro}
            </p>
          )}
          <TeamMembers teamMembers={teamMembers} />
        </div>
      </Layout>
    </div>
  );
}

function TeamMembers({ teamMembers }) {
  return (
    <>
      <SEO title="Team" />
      <div
        css={{
          maxWidth: "1200px",
          margin: "100px auto",
          position: "relative",
          zIndex: 2,
          display: "flex",
          [mq[1]]: {
            display: "block"
          }
        }}
      >
        <h2
          css={{
            color: colors.light,
            width: "203px",
            marginTop: 0,
            [mq[1]]: {
              marginLeft: "2.2rem"
            }
          }}
        >
          Team
        </h2>

        <div
          css={{
            marginLeft: "10rem",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridTemplateRows: "auto",
            gridColumnGap: "5rem",
            gridRowGap: "5rem",
            [mq[1]]: {
              display: "block",
              margin: "auto",
              width: "80%"
            }
          }}
        >
          {teamMembers.map(member => (
            <div css={{ color: colors.light }} key={member.name}>
              <div
                css={{
                  marginBottom: "4rem",
                  maxHeight: "108px",
                  height: "108px",
                  minHeight: "108px",
                  [mq[1]]: {
                    height: "100%",
                    maxHeight: "unset",
                    minHeight: "unset",
                    margin: "auto"
                  }
                }}
              >
                <h2 css={{ margin: 0 }}>{member.name}</h2>
                <h3
                  css={{
                    marginTop: "0.5rem",
                    [mq[1]]: {
                      marginBottom: "1.8rem",
                      maxHeight: "unset"
                    }
                  }}
                >
                  {member.position}
                </h3>
              </div>
              <p css={{ marginBottom: "3rem" }}>{member.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Team;

export const pageQuery = graphql`
  query teamPage {
    pageContent: mdx(fileAbsolutePath: {regex: "/content/about/"}, slug: {eq: "about/team.en"}) {
      frontmatter {
        title
        intro
        team_members {
          name
          position
          description
        }
      }
    }
  }
`;
